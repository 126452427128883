// --------------------------------	//
// 		    COLORS       			//
// --------------------------------	//


.aubanner-2:hover:after {
    background-color: $primary-color !important;
}
.bg-staff, .bg-dark-staff.aubanner-2:hover:after {
    background-color: $staff-color !important;
}
.bg-student, .bg-dark-student.aubanner-2:hover:after {
    background-color: $student-color !important;
}
.bg-shared, .bg-dark-shared.aubanner-2:hover:after {
    background-color: $shared-color !important;
}
.bg-phd, .bg-dark-phd.aubanner-2:hover:after {
    background-color: $phd-color !important;
}
.bg-red, .bg-dark-red.aubanner-2:hover:after {
    background-color: $red-color !important;
}
.bg-yellow, .bg-dark-yellow.aubanner-2:hover:after {
    background-color: $yellow-color !important;
}
.bg-orange, .bg-dark-orange.aubanner-2:hover:after {
    background-color: $orange-color !important;
}
.bg-magenta, .bg-dark-magenta.aubanner-2:hover:after {
    background-color: $magenta-color !important;
}
.bg-gray, .bg-dark-gray.aubanner-2:hover:after {
    background-color: $gray-color !important;
}
.bg-lightgray, .bg-dark-gray.aubanner-2:hover:after{
    background-color: #f0efef !important;
    color: #333 !important;
}
.bg-white, .bg-dark-white.aubanner-2:hover:after {
    background-color: #fff !important;
    color: #333;
}
.white.aubanner-2.white:hover:after {
    color: #fff;
}
.white.aubanner-2:after {
    background-color: #f0efef !important;
    color: #333 !important;
}
.white.aubanner-2.white:hover:after {
    color: #fff !important;
}

.bg-light-default {
    background-color: rgba($secondary-color,.15);
}

.bg-light-student {
    background-color: rgba($student-color,.15);
}

.bg-light-staff {
    background-color: rgba($staff-color,.15);
}

.bg-light-shared {
    background-color: rgba($shared-color,.15);
}

.bg-light-phd {
    background-color: rgba($phd-color,.15);
}

.white.aubanner-2:hover:after,.lightgray.aubanner-2:hover:after  {
    background-color: #333 !important;
}
.bg-dark-default {
    background-color: $primary-secondary-color !important;
}
.button.bg-dark-default:hover {
    background-color: lighten($primary-secondary-color,10%) !important;
}
.bg-dark-primary, .button.bg-primary:hover {
    background-color: $primary-secondary-color !important;
}
.bg-dark-staff, .button.bg-staff:hover {
    background-color: $staff-secondary-color !important;
}
.button.bg-dark-staff:hover {
    background-color: lighten($staff-secondary-color,10%) !important;
}
.bg-dark-student, .button.bg-student:hover {
    background-color: $student-secondary-color !important;
}
.button.bg-dark-student:hover {
    background-color: lighten($student-secondary-color,10%) !important;
}
.bg-dark-shared, .button.bg-shared:hover {
    background-color: $shared-secondary-color !important;
}
.button.bg-dark-shared:hover {
    background-color: lighten($shared-secondary-color,10%) !important;
}
.bg-dark-phd, .button.bg-phd:hover {
    background-color: $phd-secondary-color !important;
}
.button.bg-dark-phd:hover {
    background-color: lighten($phd-secondary-color,10%) !important;
}
.bg-dark-red, .button.bg-red:hover {
    background-color: $red-secondary-color !important;
}
.button.bg-dark-red:hover {
    background-color: lighten($red-secondary-color,10%) !important;
}
.bg-dark-yellow, .button.bg-yellow:hover {
    background-color: $yellow-secondary-color !important;
}
.button.bg-dark-yellow:hover {
    background-color: lighten($yellow-secondary-color,10%) !important;
}
.bg-dark-orange, .button.bg-orange:hover {
    background-color: $orange-secondary-color !important;
}
.button.bg-dark-orange:hover {
    background-color: lighten($orange-secondary-color,10%) !important;
}
.bg-dark-gray, .button.bg-gray:hover {
    background-color: $gray-secondary-color !important;
}
.bg-dark-lightgray, .button.bg-lightgray:hover {
    background-color: darken(#f0efef,15%) !important;
}
.bg-dark-white, .button.bg-white:hover {
    background-color: #f0efef !important;
}
.bg-dark-magenta, .button.bg-magenta:hover {
    background-color: $magenta-secondary-color !important;
}

.fg-staff, .button.bg-staff.arrow:after {
    color: $staff-color !important;
}
.fg-student, .button.bg-student.arrow:after {
    color: $student-color !important;
}
.fg-shared, .button.bg-shared.arrow:after {
    color: $shared-color !important;
}
.fg-phd, .button.bg-phd.arrow:after {
    color: $phd-color !important;
}
.fg-red, .button.bg-red.arrow:after {
    color: $red-color !important;
}
.fg-yellow, .button.bg-yellow.arrow:after {
    color: $yellow-color !important;
}
.fg-orange, .button.bg-orange.arrow:after {
    color: $orange-color !important;
}
.fg-magenta, .button.bg-magenta.arrow:after {
    color: $magenta-color !important;
}
.fg-gray, .button.bg-gray.arrow:after {
    color: $gray-color !important;
}
.fg-white {
    color: #fff !important;
}
.fg-default {
    color: $primary-secondary-color !important;
}

/*############### GENERIC COLORS AND GRADIENT SETS (Rows, lists etc.) ############ */
.grey_gradient1, .au_grey-bg{background-color:#87888a !important;}
.au_grey-fg{color:#87888a !important;}
.grey_gradient2{background-color:#a5a6a7 !important;}
.grey_gradient3{background-color:#c3c4c5 !important;}
.grey_gradient4{background-color:#e1e1e2 !important;}

.cyan_gradient1, .au_cyan-bg {background-color:#009ee0 !important;}
.au_cyan-fg {color:#009ee0 !important;}
.cyan_gradient2{background-color:#40b6e8 !important;}
.cyan_gradient3{background-color:#7fceef !important;}
.cyan_gradient4{background-color:#b2e2f6 !important;}

.purple_gradient1, .au_purple-bg{background-color:#655a9f !important;}
.au_purple-fg{color:#655a9f !important;}
.purple_gradient2{background-color:#8c83b7 !important;}
.purple_gradient3{background-color:#b2accf !important;}
.purple_gradient4{background-color:#d0cde2 !important;}

.green_gradient1, .au_green-bg{background-color:#7ab51d !important;}
.au_green-fg{color:#7ab51d !important;}
.green_gradient2{background-color:#9bc856 !important;}
.green_gradient3{background-color:#bcda8e !important;}
.green_gradient4{background-color:#d7e9bb !important;}

.blue_gradient1, .au_blue-bg{background-color:#003d85 !important;}
.au_blue-fg{color:#003d85 !important;}
.blue_gradient2{background-color:#406ea4 !important;}
.blue_gradient3{background-color:#7f9ec2 !important;}
.blue_gradient4{background-color:#b8c4da !important;}