.no-margin-top {
  margin-top: 0 !important;
}

.no-margin-bottom {
  margin-bottom: 0 !important;
}

.no-padding-top {
  padding-top: 0 !important;
}

.no-padding-bottom {
  padding-bottom: 0 !important;
}

.align-left {
  text-align: left !important;
}

.align-right {
  text-align: right !important;
}

.align-center {
  text-align: center !important;
}
.right {
  float: right !important;
}

.left {
  float: left !important;
}