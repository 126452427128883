table {
  &.au_table-style1 {
    width: 100%;

    td {
      vertical-align: top;
      padding-bottom: 1em;
    }

    tr td:first-child {
      text-transform: uppercase;
      font-family: "AUPassataRegular";
      font-size: 90%;
    }
    tr:nth-of-type(even) {
      background-color: transparent !important;
    }
  }

  &.au_table-style2, &[class^="au_table_style2-"]{
    border-collapse: collapse;
    border-style: hidden;
    margin: 2em 0;
    width: 100%;

    td {
      background-color: #b2e2f6;
      vertical-align: top;
    }

    th {
      font-family: "AUPassataRegular";
      background-color: #7fceef;
      text-transform: uppercase;
    }

    & td, & th {
      border: 10px solid #fff;
      padding: 6px 10px;
    }

    em {
      font-style: normal;
      color: #fff
    }

    @at-root .au_table_style2-green td, &.au_green-bg td {
      background-color: #d7e9bb !important;
    }

    @at-root .au_table_style2-green th, &.au_green-bg th {
      background-color: #bcda8e !important;
    }

    @at-root .au_table_style2-lightblue td, &.au_blue-bg td {
      background-color: #b8c4da !important;
    }

    @at-root .au_table_style2-lightblue th, &.au_blue-bg th {
      background-color: #7f9ec2 !important;
    }

    @at-root .au_table_style2-purple td, &.au_purple-bg td {
      background-color: #d0cde2 !important;
    }
    @at-root .au_table_style2-purple th, &.au_purple-bg th {
      background-color: #b2accf !important;
    }

    @at-root .au_table_style2-grey td, &.au_grey-bg td {
      background-color: #e1e1e2 !important;
    }

    @at-root .au_table_style2-grey th, &.au_grey-bg th {
      background-color: #c3c4c5 !important;
    }
  }

  // Background dark
  .au_bg_dark {
    background: $primary-secondary-color;;
    color: #fff !important;
  }
  .au_bg_medium {
    background: rgba($primary-secondary-color, .25);
  }
  .au_bg_light {
    background: rgba($primary-secondary-color, .125);
  }
  .au_bg_grey {
    background: #e1e1e2;
  }

  .student & {
    .au_bg_dark {
      background: $student-secondary-color;;
      color: #fff !important;
    }
    .au_bg_medium {
      background: rgba($student-secondary-color, .25);
    }
    .au_bg_light {
      background: rgba($student-secondary-color, .125);
    }
  }

  .staff & {
    .au_bg_dark {
      background: $staff-secondary-color;;
      color: #fff !important;
    }
    .au_bg_medium {
      background: rgba($staff-secondary-color, .25);
    }
    .au_bg_light {
      background: rgba($staff-secondary-color, .125);
    }
  }

  .shared & {
    .au_bg_dark {
      background: $shared-secondary-color;;
      color: #fff !important;
    }
    .au_bg_medium {
      background: rgba($shared-secondary-color, .25);
    }
    .au_bg_light {
      background: rgba($shared-secondary-color, .125);
    }
  }

  .phd & {
    .au_bg_dark {
      background: $phd-secondary-color;;
      color: #fff !important;
    }
    .au_bg_medium {
      background: rgba($phd-secondary-color, .25);
    }
    .au_bg_light {
      background: rgba($phd-secondary-color, .125);
    }
  }

  // Foreground dark
  .au_fg_dark {
    color: $primary-secondary-color;
  }
  .au_fg_medium {
    color: rgba($primary-secondary-color, .25);
  }
  .au_fg_light {
    color: rgba($primary-secondary-color, .125);
  }

  .student & {
    .au_fg_dark {
      color: $student-secondary-color;
    }
    .au_fg_medium {
      color: rgba($student-secondary-color, .25);
    }
    .au_fg_light {
      color: rgba($student-secondary-color, .125);
    }
  }

  .staff & {
    .au_fg_dark {
      color: $staff-secondary-color;
    }
    .au_fg_medium {
      color: rgba($staff-secondary-color, .25);
    }
    .au_fg_light {
      color: rgba($staff-secondary-color, .125);
    }
  }

  .shared & {
    .au_fg_dark {
      color: $shared-secondary-color;
    }
    .au_fg_medium {
      color: rgba($shared-secondary-color, .25);
    }
    .au_fg_light {
      color: rgba($shared-secondary-color, .125);
    }
  }

  .phd & {
    .au_fg_dark {
      color: $phd-secondary-color;
    }
    .au_fg_medium {
      color: rgba($phd-secondary-color, .25);
    }
    .au_fg_light {
      color: rgba($phd-secondary-color, .125);
    }
  }

  // Border top
  .au_bt_dark {
    border-top: 1px solid $primary-secondary-color;
  }
  .au_bt_medium {
    border-top: 1px solid rgba($primary-secondary-color,.25);
  }
  .au_bt_light {
    border-top: 1px solid rgba($primary-secondary-color, .125);
  }

  .student & {
    .au_bt_dark {
      border-top: 1px solid $student-secondary-color;
    }
    .au_bt_medium {
      border-top: 1px solid rgba($student-secondary-color,.25);
    }
    .au_bt_light {
      border-top: 1px solid rgba($student-secondary-color, .125);
    }
  }

  .staff & {
    .au_bt_dark {
      border-top: 1px solid $staff-secondary-color;
    }
    .au_bt_medium {
      border-top: 1px solid rgba($staff-secondary-color,.25);
    }
    .au_bt_light {
      border-top: 1px solid rgba($staff-secondary-color, .125);
    }
  }

  .shared & {
    .au_bt_dark {
      border-top: 1px solid $shared-secondary-color;
    }
    .au_bt_medium {
      border-top: 1px solid rgba($shared-secondary-color,.25);
    }
    .au_bt_light {
      border-top: 1px solid rgba($shared-secondary-color, .125);
    }
  }

  .phd & {
    .au_bt_dark {
      border-top: 1px solid $phd-secondary-color;
    }
    .au_bt_medium {
      border-top: 1px solid rgba($phd-secondary-color,.25);
    }
    .au_bt_light {
      border-top: 1px solid rgba($phd-secondary-color, .125);
    }
  }

  // Border bottom
  .au_bb_dark {
    border-bottom: 1px solid $primary-secondary-color;
  }
  .au_bb_medium {
    border-bottom: 1px solid rgba($primary-secondary-color, .25);
  }
  .au_bb_light {
    border-bottom: 1px solid rgba($primary-secondary-color, .125);
  }

  .student & {
    .au_bb_dark {
      border-bottom: 1px solid $student-secondary-color;
    }
    .au_bb_medium {
      border-bottom: 1px solid rgba($student-secondary-color,.25);
    }
    .au_bb_light {
      border-bottom: 1px solid rgba($student-secondary-color, .125);
    }
  }

  .staff & {
    .au_bb_dark {
      border-bottom: 1px solid $staff-secondary-color;
    }
    .au_bb_medium {
      border-bottom: 1px solid rgba($staff-secondary-color,.25);
    }
    .au_bb_light {
      border-bottom: 1px solid rgba($staff-secondary-color, .125);
    }
  }

  .shared & {
    .au_bb_dark {
      border-bottom: 1px solid $shared-secondary-color;
    }
    .au_bb_medium {
      border-bottom: 1px solid rgba($shared-secondary-color,.25);
    }
    .au_bb_light {
      border-bottom: 1px solid rgba($shared-secondary-color, .125);
    }
  }

  .phd & {
    .au_bb_dark {
      border-bottom: 1px solid $phd-secondary-color;
    }
    .au_bb_medium {
      border-bottom: 1px solid rgba($phd-secondary-color,.25);
    }
    .au_bb_light {
      border-bottom: 1px solid rgba($phd-secondary-color, .125);
    }
  }

}

/* TABLES-TO-LIST */

.au_responsive_table_list {

  margin: 0 !important;

  li {

    padding: 1em 1em 0 1em !important;
    margin-bottom: 1em !important;
    background: #f0f0f0 !important;
    overflow: auto;

    h3 {
      margin: 0 0 1em 0 !important;
      font-weight: bold;
      background: #ccc;
      padding: .5em;
    }

    p {
      background: #e4e4e4;
      padding: .5em;
    }
  }

  &.purple {
    li {
      background: #d0cde2 !important;

      h3 {
        background: #b2accf;
      }

      p {
        background: #c1bbe0;
      }
    }
  }

  &.green {
    li {
      background: #d7e9bb !important;

      h3 {
        background: #bcda8e;
      }

      p {
        background: #c8e59c;
      }
    }
  }

  &.blue {
    li {
      background: #b8c4da !important;

      h3 {
        background: #7f9ec2;
      }

      p {
        background: #afbad0;
      }
    }
  }

}

/* GENERIC RESPONSIVE TABLE FIX */

div.responsive-table-container {
  width: 100%;
  overflow-y: auto;
  margin: 0 0 1em;
  background-repeat: repeat-y;
  background-position: top right;
}

div.responsive-table-container::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 14px;
  height: 14px;
}

div.responsive-table-container::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 3px solid #fff;
  background-color: rgba(0, 0, 0, .3);
}

/* EDUTABLES - OMG EDDI */

div.studieoversigt {

  table {
    background-color: transparent;
    empty-cells: show;
    width: 100%;
    border-collapse: collapse;
    font-size: 90%;
    table-layout: fixed;
    clear: both;

    tbody, thead {
      background: transparent !important;
    }

    tr {
      background-color: transparent !important;
    }

    th {
      width: 10%;
      font-weight: bold;
    }

    td {
      padding: 5px;
    }

    td[rowspan="2"] {
      height: 230px;

      .element {
        height: 220px;
      }
    }

    td[rowspan="3"] {
      height: 340px;

      .element {
        height: 330px;
      }
    }

    td[rowspan="4"] {
      height: 470px;

      .element {
        height: 460px;
      }
    }

    td[rowspan="6"] {
      height: 710px;

      .element {
        height: 700px;
      }
    }

    div.left {
      text-align: center;
      padding-left: 0;
      padding-right: 0;
    }

    .obligatorisk {
      background-color: #f2e4d4;
    }

    .valgfag {
      background-color: #d1e1d6;
    }

    .tilvalg {
      background-color: #e9ccda;
    }

    .stottefag {
      background-color: #cfe1e7;
    }

    .blank {
      background-color: #dddddd;
    }

  }

  div.enkeltStudieoversigt {
    width: 100%;
    margin-bottom: 2em;

    a.aulink:link {
      text-decoration: none !important;

      &:hover {
        text-decoration: none !important;
      }
    }

  }

  a.aulink:link {
    text-decoration: none !important;

    .obligatorisk:hover {
      color: #f2e4d4 !important;

      div {
        color: #f2e4d4 !important;
      }
    }

    .valgfag:hover {
      color: #d1e1d6 !important;

      div {
        color: #d1e1d6 !important;
      }
    }

    .tilvalg:hover {
      color: #e9ccda !important;

      div {
        color: #e9ccda !important;
      }
    }

    .stottefag:hover {
      color: #cfe1e7 !important;

      div {
        color: #cfe1e7 !important;
      }
    }
  }

  a.aulink:link, a.aulink:visited {
    color: #333 !important;
  }

  .elementtekst {
    padding: 0 2px 0 0;
    margin: 0;
  }

  .element {
    margin: 0;
    overflow: visible;
    word-wrap: break-word;
    height: 100px;
    padding: 5px;
    position: relative;
    clear: both;
  }

  .studiediagram_navn {
    position: relative;
    top: 0;
    text-decoration: underline;
  }

  .studiediagram_ects {
    position: absolute;
    bottom: 0;
    padding: 6px 2px 6px 2px;
  }

  a .element:hover {
    background-color: #676767 !important;
  }
}

.studiediagram_navn + div {
  bottom: 5px;
  position: absolute;
}

/* EDUTABLES - AWESOME! */

.au_edutable_track {
  display: none;

  &.active {
    display: block;
  }
}

.au_edutable_legend {
  float: right;
  clear: both;
  margin-right: .4em;

  dt {
    padding: .5em;
    width: 10em;
    margin-bottom: 0;
  }

  dd {
    margin-bottom: .4rem;
  }

  &:after {
    content: "";
    clear: both;
    display: block;
  }
}

.au_edutable_mandatory {
  background-color: #f2e4d4;
}

.au_edutable_optional {
  background-color: #d1e1d6;
}

.au_edutable_supplementary {
  background-color: #e9ccda;
}

.au_edutable_support {
  background-color: #cfe1e7;
}

.au_edutable_eddi_container {
  overflow: auto;
}

.studydiagram_legend {
  display: none;
}

.main .au_responsive_education_overview {
  background: #f1eeef;
  overflow: auto;
  padding: 1rem;

  ul {
    margin-bottom: 2em;

    &:last-child {
      margin-bottom: 0em;
    }

    li {
      background: none;
      padding: 0;

    }

    h3 {
      font-family: "AUPassataBold";
      text-transform: uppercase;
      margin: 1rem 0;
    }

    p {
      padding: .5em;
      margin-bottom: .25em;

      &.edu_support {
        background: #cfe1e7;
      }

      &.edu_mandatory {
        background: #f2e4d4;
      }

      &.edu_supplementary {
        background: #e9ccda;
      }

      &.edu_optional {
        background: #d1e1d6;
      }

      &.edu_empty {
        visibility: hidden;
      }

      &:last-child {
        margin-bottom: 0em;
      }

      span:last-child {
        float: right;
      }

      a {
        color: #000;
        text-decoration: none;
        border: none;
      }
    }
  }
}

table tr th[align="right"], table tr td[align="right"] {
  text-align: right;
}

table tr th[align="center"], table tr td[align="center"] {
  text-align: center;
}