@media print {
  @for $i from 1 through $grid-column-count {
    .medium-#{$i} {
      flex: 0 0 percentage($i / $grid-column-count);
    }
  }

  a[href]:after, a[href*="/"]:after, a[href*="/"]:visited:after {
    content: normal;
  }

  a {
    text-decoration: none !important;
  }

  .button, .au_button a, .au_button, .au_focus * {
      color: #fff !important;
  }

  .left-menu, .au_news-list-browse, .section-header,.language-selector, .header-menu {
    display: none;
  }
  .main {
    flex:0 0 100%;
    max-width: 100%;
  }
  footer {
    display:none;
  }
  footer.global .columns {
    background: transparent;
  }
  .aubanner, .aubanner.aubanner-2,.aubanner.dark {
    p, a, h2, h3 {
      color: #fff !important;
    }
  }
  .aubanner.aubanner-2.white {
    p, a, h2, h3 {
      color: #333 !important;
    }
  }

  //WDMTY-393
  .footer.globalfooter {
    display: none;
  }

  // KOM0014405
  .neutral-container div[class*=bg-]:not(.aubanner) {
    background-color: transparent!important;
    border: 1px solid black;
  }
  header {
    display: none;
  }
  .row {
    display: block;
  }
  .column {
    max-width: 100%;
  }
  .clearfix {
    page-break-inside: avoid;
  }
}
