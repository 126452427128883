// --------------------------------	//
// 		    BUTTONS     			//
// --------------------------------	//
.au_dropdown {
  margin-bottom:1em;
}
.au_button {
  @include button;
  a {
    color: #fff;
    font-family: "AUPassataRegular", Verdana, sans-serif;
    text-decoration: none !important;
  }
  .au_dropdown & {
    background-color: inherit;
    margin-bottom:0;
    width: 100%;
    font-family: "AUPassataRegular";
  }
}
.button {
  position: relative;
  text-decoration: none !important;
  -webkit-appearance: none !important;
}

.stafflinks {
  a {
    @include button;
    color: #fff;
    font-family: "AUPassataRegular", Verdana, sans-serif;
    text-decoration: none !important;
  }
  li {
    padding-left: 0;
    &:before {
      content: "" !important;
    }
  }
}

.au_list-fancy-style1 {
  [class^="icon-"]:before, [class*=" icon-"]:before {
    font-size: 2.4rem;
    line-height: 1em;
    margin: 0;
    margin-right: .35em;
    vertical-align: top;
  }
}

.button[class^="icon-"]:before, .button[class*=" icon-"]:before {
  font-size: 2.5rem;
  line-height: .85em;
  margin: 0;
  margin-right: .35em;
  vertical-align: middle;
}

.button[class*=" icon-"],
.button[class^="icon-"] {
  padding: 1.03rem 1em;
}

.button.large[class*=" icon-"]::before,
.button.large[class^="icon-"]::before {
  font-size: 4.15rem;
}

.button.large[class*=" icon-"],
.button.large[class^="icon-"] {
  padding: 0.735rem 1em;
}

.button.small[class*=" icon-"]::before,
.button.small[class^="icon-"]::before {
  font-size: 2.32rem;
}

.button.small[class*=" icon-"],
.button.small[class^="icon-"] {
  padding: 0.634rem 1em;
}

.button.tiny[class*=" icon-"]::before,
.button.tiny[class^="icon-"]::before {
  font-size: 1.63rem;
}

.button.tiny[class*=" icon-"],
.button.tiny[class^="icon-"] {
  padding: 0.657rem 1em;
}

.button.arrow {
  padding-right: 3.4em;
  &:after {
    transition: .2s;
    width: 28px;
    height: 28px;
    border-radius: 14px;
    color: $primary-color;
    text-align: center;
    background: #fff;
    position: absolute;
    right: 1em;
    top: .65em;
    line-height: 2em;
    content: "≥";
    font-family: "au_icons";
    font-size: .8em;
    margin-top: 0;
  }
  &.large:after {
    width: 32px;
    height: 32px;
    border-radius: 16px;
    font-size: .75em;
  }
  &.small:after {
    width: 22px;
    height: 22px;
    border-radius: 11px;
  }
  &.tiny:after {
    width: 18px;
    height: 18px;
    border-radius: 9px;
  }
}

.button {
  &.small-margin {
    margin-bottom: .25em;
  }
  &.large-margin {
    margin-bottom: map-get($grid-column-gutter, medium);
  }

  &.arrow {
    &.bg-white {
      color: rgb(10, 10, 10);

      &:hover {
        color: #fff;
      }
    }
  }
}

div[class*="bg-dark"] {
  .button {
    &.arrow {
      &.bg-white {
        color: rgb(10, 10, 10);

        &:hover {
          background-color: rgba(255, 255, 255, 0.85) !important;
          color: rgb(10, 10, 10);
          opacity: 1;
        }
      }
    }
  }
}

.bg-dark-white, .button.bg-white:hover {
  background-color: rgba(0, 0, 0, 1) !important;
  opacity: 0.9;
}

/*########################## FANCY LIST STYLES ########################## */
/* Fancy list style 1:List elements appears as big buttons */
#au_content ul.au_list-fancy-style1 li, .au_misc_related_content ul.au_list-fancy-style1 li {
  display: block;
  background-color: #19a9e4;
  font-family: "AUPassataRegular";
  font-size: 16px;
  text-transform: uppercase;
  background-image: none !important;
  margin-bottom: 1.2em;
  padding: 0 !important;
  white-space: nowrap;
}

ul.au_list-fancy-style1 li:before {
  content: "" !important;
}

#au_content ul.au_list-fancy-style1 li a, .au_misc_related_content ul.au_list-fancy-style1 li a {
  border: 0 !important;
  color: #fff;
  display: block;
  padding: 0.8em;
  text-decoration: none !important;
}
