@font-face {
  font-family: 'au_icons';
  src: url('https://fonts.au.dk/fonts/au_icons.eot');
  src: url('https://fonts.au.dk/fonts/au_icons.eot#iefix') format('embedded-opentype'), url('https://fonts.au.dk/fonts/au_icons.woff') format('woff'), url('https://fonts.au.dk/fonts/au_icons.woff2') format('woff'), url('https://fonts.au.dk/fonts/au_icons.ttf') format('truetype'), url('https://fonts.au.dk/fonts/au_icons.svg#au_icons') format('svg');
  font-weight: normal;
  font-style: normal;
}

/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */

/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */

/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'au_icons';
    src: url('../font/au_icons.svg?70343072#au_icons') format('svg');
  }
}
*/

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "au_icons";
  font-style: normal;
  font-weight: normal;
  speak: none;
  cursor: pointer;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-plus:before {
  content: '\2b';
}

/* '+' */

.icon-minus:before {
  content: '\2d';
}

/* '-' */

.icon-calendar:before {
  content: '\30';
}

/* '0' */

.icon-pencil:before {
  content: '\31';
}

/* '1' */

.icon-user:before {
  content: '\32';
}

/* '2' */

.icon-print-1:before {
  content: '\33';
}

/* '3' */

.icon-download-1:before {
  content: '\34';
}

/* '4' */

.icon-upload:before {
  content: '\35';
}

/* '5' */

.icon-trash:before {
  content: '\36';
}

/* '6' */

.icon-search:before {
  content: '\37';
}

/* '7' */

.icon-floppy:before {
  content: '\38';
}

/* '8' */

.icon-chat:before {
  content: '\39';
}

/* '9' */

.icon-bullet:before {
  content: '\3e';
}

/* '&gt;' */

.icon-logo:before {
  content: '\41';
}

/* 'A' */

.icon-facebook:before {
  content: '\46';
}

/* 'F' */

.icon-blackboard:before {
  content: '\42';
}

/* 'B' */

.icon-home:before {
  content: '\48';
}

/* 'H' */

.icon-linkedin:before {
  content: '\49';
}

/* 'I' */

.icon-footer_logo:before {
  content: '\4c';
}

/* 'L' */

.icon-mail:before {
  content: '\4d';
}

/* 'M' */

.icon-phone:before {
  content: '\50';
}

/* 'P' */

.icon-instagram:before {
  content: '\53';
}

/* 'S' */

.icon-twitter:before {
  content: '\54';
}

/* 'T' */

.icon-udays:before {
  content: '\55';
}

/* 'U' */

.icon-vimeo:before {
  content: '\56';
}

/* 'V' */

.icon-youtube:before {
  content: '\59';
}

/* 'Y' */

.icon-systems:before {
  content: '\5a';
}

/* 'Z' */

.icon-apply:before {
  content: '\61';
}

/* 'a' */

.icon-read:before {
  content: '\62';
}

/* 'b' */

.icon-close:before {
  content: '\63';
}

/* 'c' */

.icon-download:before {
  content: '\64';
}

/* 'd' */

.icon-email:before {
  content: '\65';
}

/* 'e' */

.icon-find:before {
  content: '\66';
}

/* 'f' */

.icon-obs:before {
  content: '\69';
}

/* 'i' */

.icon-info:before {
  content: '\6a';
}

/* 'j' */

.icon-legal:before {
  content: '\6c';
}

/* 'l' */

.icon-menu:before {
  content: '\6d';
}

/* 'm' */

.icon-burger:before {
  content: '\6e';
}

/* 'n' */

.icon-pin:before {
  content: '\6f';
}

/* 'o' */

.icon-pin-old:before {
  content: '\70';
}

/* 'p' */

.icon-question:before {
  content: '\71';
}

/* 'q' */

.icon-rss:before {
  content: '\72';
}

/* 'r' */

.icon-subscribe:before {
  content: '\73';
}

/* 's' */

.icon-settings:before {
  content: '\74';
}

/* 't' */

.icon-www:before {
  content: '\77';
}

/* 'w' */

.icon-bullet_rounded:before {
  content: '\2265';
}

/* '≥' */

.icon-search::before {
  content: '\75';
}

.icon-word::before {
  content: '\78';
}

.icon-excell::before {
  content: '\79';
}

.icon-acrobat::before {
  content: '\7a';
}