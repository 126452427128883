.au_collapsible {
  background-color: #f0eeef;
  padding: 1rem;
  margin-bottom: 1rem;
  position: relative;
  clear: both;

  .row {
    background: transparent;
  }

  .main & h2, .main & h3 {
    margin-top: 0;
  }

  > .csc-header {
    cursor: pointer;
    margin-bottom: 2rem;
  }

  & .csc-header h1, #au_content .column & .csc-header h1 {
    color: $primary-color;
    border: 0;
    padding: 0 1.5rem 0 0;
    margin: 0;
    font-size: 2rem;
    .bachelor & {
      color: $staff-color;
    }
  }

  & .csc-header h2, #au_content .column & .csc-header h2 {
    color: $primary-color;
    border: 0;
    padding: 0 1.5rem 0 0;
    margin: 0;
    font-size: 1.818182rem;

    .bachelor & {
      color: $staff-color;
    }
  }

  & .csc-header h3, #au_content .column & .csc-header h3 {
    color: $primary-color;
    border: 0;
    padding: 0 1.5rem 0 0;
    margin: 0;
  }

  #au_content & ul {
    margin-bottom: 0;
  }

}


.au_collapsed {

  > .csc-header {
    margin-bottom: 0;
  }

  &.csc-frame > .csc-header ~ *,&.csc-frame > :not(.csc-header) {
    display: none;
  }

  &.csc-frame > .csc-header h1, &.csc-frame > .csc-header h2 {
    color: #000 !important;
    margin: 0;

    .bachelor & {
      color: $staff-color !important;
    }
  }
}


.csc-frame.au_collapsible > .csc-header:after, .csc-frame.au_collapsed > .csc-header:after {
  font-family: 'au_icons';
  float: right;
  color: $primary-color;
  font-size: 2.9090901rem;
  position: absolute;
  margin-top: .15rem;
  right: .3rem;

  .bachelor & {
    color: $staff-color;
  }
}

.csc-frame.au_collapsible > .csc-header:after {
  content: '-';
  top: 0;
}
.csc-frame.au_collapsed > .csc-header:after {
  content: '+';

}


.au_collapsible_new {
  .au_collapsible .au_collapsible {
     background-color: #e2e2e2;
   }
  .au_collapsible .au_collapsible.au_collapsed {
    background-color: #f0eeef;
    border-top: 1px solid #dedcdc;
  }
  .au_collapsible .csc-frame.au_collapsed>.csc-header:after {
    color: #999!important;
  }
}

/* Collapsible indeni neutral container */
.neutral-container {
  .bg-dark-student .au_collapsible_new .au_collapsible .au_collapsible {
    background-color: lighten($student-secondary-color,10%) !important;
  }
  .bg-dark-shared .au_collapsible_new .au_collapsible .au_collapsible {
    background-color: lighten($shared-secondary-color,10%) !important;
  }
  .bg-dark-phd .au_collapsible_new.au_collapsible .au_collapsible {
    background-color: lighten($phd-secondary-color,10%) !important;
  }
  .bg-dark-staff .au_collapsible_new .au_collapsible .au_collapsible {
    background-color: lighten($staff-secondary-color,10%) !important;
  }
  .bg-dark-yellow .au_collapsible_new .au_collapsible .au_collapsible {
    background-color: lighten($yellow-secondary-color,10%) !important;
  }
  .bg-dark-orange .au_collapsible_new .au_collapsible .au_collapsible {
    background-color: lighten($orange-secondary-color,10%) !important;
  }
  .bg-dark-red .au_collapsible_new .au_collapsible .au_collapsible {
    background-color: lighten($red-secondary-color,10%) !important;
  }
  .bg-dark-magenta .au_collapsible_new .au_collapsible .au_collapsible {
    background-color: lighten($magenta-secondary-color,10%) !important;
  }
  .bg-dark-gray .au_collapsible_new .au_collapsible .au_collapsible {
    background-color: lighten($gray-secondary-color,10%) !important;
  }
}
.neutral-container div[class*=bg-dark] .au_collapsible_new {
  h1, h2, h3 {
    color: #fff!important;
  }
  .au_collapsible .au_collapsible.au_collapsed {
    background-color:transparent!important;
  }
  .csc-frame.au_collapsible > .csc-header:after {
    color: #fff;
  }
}

.neutral-container div[class*=bg-light] .au_collapsible_new {
  .au_collapsible.au_collapsed {
    background-color:transparent!important;
  }
}
.neutral-container {
  .bg-light-student .au_collapsible_new .au_collapsible .au_collapsible {
    background-color: rgba($student-color,.35)!important;
  }
  .bg-light-shared .au_collapsible_new .au_collapsible .au_collapsible {
    background-color: rgba($shared-color,.35)!important;
  }
  .bg-light-phd .au_collapsible_new .au_collapsible .au_collapsible {
    background-color: rgba($phd-color,.35)!important;
  }
  .bg-light-staff .au_collapsible_new .au_collapsible .au_collapsible {
    background-color: rgba($staff-color,.35)!important;
  }
  .bg-light-yellow .au_collapsible_new .au_collapsible .au_collapsible {
    background-color: rgba($yellow-color,.35)!important;
  }
  .bg-light-orange .au_collapsible_new .au_collapsible .au_collapsible {
    background-color: rgba($orange-color,.35)!important;
  }
  .bg-light-red .au_collapsible_new .au_collapsible .au_collapsible {
    background-color: rgba($red-color,.35)!important;
  }
  .bg-light-magenta .au_collapsible_new .au_collapsible .au_collapsible {
    background-color: rgba($magenta-color,.35)!important;
  }
  .bg-light-gray .au_collapsible_new .au_collapsible .au_collapsible {
    background-color: rgba($gray-color,.35)!important;
  }
}
