/*########################## COOKIES ALERT ##########################*/
#au_cookiesalert {

  overflow: hidden;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-color: #3a3a3a;
  background-color: rgba(58, 58, 58, 0.97);
  z-index: 9999;
  color: #fff;
  margin: 0 auto;
  overflow: auto;
  padding: 2rem;

  h1 {
    border: none;
    padding: 0;
    font-size: 170%;
    line-height: 1.2em;
    margin-bottom: 1em;
    color: #fff;
  }

  h2 {
    text-align: center;
    padding-bottom: 1em;
  }

  a {
    color: #fff;
    text-decoration: underline;
  }

  button {
    color: #fff;
    cursor: pointer;
    font-family: "AUPassataRegular";
  }

  p {
    float: left;
    max-width: 600px;

    &.button {
      float: right;
      background: rgb(122, 181, 29);
    }
  }

  @include breakpoint(small only) {
    padding-top: 1rem;
    padding-bottom: 1rem;

    h1 {
      font-size: 130% !important;
    }

    p {
      &.button {
        padding-top: 0.8rem;

        button {
          font-size: 1.2rem;
        }
      }
    }
  }
}

/*########################## IE WARNING ##########################*/

.ie-warning {
  h1, h2, h3, h4 {
    margin-bottom: .1rem;
    color: #0a0a0a;
  }
}

.ie-warning a {
  color: #0a0a0a;
  padding: 0.6rem;
  flex: 1 1 25rem;
  max-width: 63.636365rem;

}

.ie-warning a:hover {
  text-decoration: none;
  color: rgba(10, 10, 10, 0.65);
}

.ie-warning p {
  margin-bottom: .1rem;
  color: #0a0a0a;
}

.ie-warning p:last-chilf {
  margin-bottom: 0rem;
}

.ie-warning {
  background-color: #fabb00;
  margin-bottom: 0;
  padding: 0.6rem;
  overflow: auto;
  position: relative;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}