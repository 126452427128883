/*########################*/

/* --- PURE EXTENSION --- */

/*########################*/

div.pure-basic-person-single {
  padding: 20px;
  background: url('//www.au.dk/typo3conf/ext/pure/res/images/people_vcf_bg.gif') bottom left repeat-x;
  border: 1px solid #ddddd9;
  margin-left: -12px;
  margin-bottom: 2em;
  overflow: auto;
  padding-bottom: 6px
}

div.pure-basic-person-single img.photo {
  float: right;
  max-width: 160px
}

div.pure-small-person {
  margin-bottom: 1em;
  padding-bottom: 1em;
  border-bottom: 1px solid #C5C5C5
}

div#au_content div.pure-basic-person-single h2 {
  margin-top: 0
}

div#au_content div#au_vcard_areas li,
div#au_content div.pure-basic-person-single li {
  margin-left: 0
}

div#au_content div.pure-basic-person-single ul {
  margin-top: 16px;
  padding: 0
}

div#au_content div.pure-basic-person-single h3.au_vcard_emphasized_header {
  margin: 1em 0;
  border-top: 1px solid #cccbc6;
  border-bottom: 1px solid #cccbc6;
  padding: .7em 0
}

div.vcard_areas h1 {
  border-top: 1px solid #cccbc6;
  border-bottom: 1px solid #cccbc6;
  font-size: 180%;
  padding: .7em 0;
  font-weight: 400;
  text-transform: uppercase;
  margin-left: -12px;
  padding-left: 12px;
  margin-bottom: .5em
}

div.vcard_areas h2 {
  margin: 1em 0
}

.pure-tabs-ul {
  padding: 0 !important;
  width: 100%;
  height: 21px
}

.pure-tabs-ul li {
  background: none !important;
  display: inline;
  white-space: nowrap;
  padding-left: 0 !important
}

.pure-tabs-ul li a {
  padding: 6px;
  padding-top: 2px;
  border: 1px solid #ccc;
  text-decoration: none !important;
  background: url(/typo3conf/ext/pure/res/images/tabs_bg.png) bottom left repeat-x;
  -moz-border-radius-topright: 3px;
  -webkit-border-top-right-radius: 3px;
  -moz-border-radius-topleft: 3px;
  -webkit-border-top-left-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  height: 20px;
  line-height: 12px
}

.pure-tabs-ul li.pure-tabs-selected a,
div#au_content .pure-tabs-ul li.pure-tabs-selected a:hover {
  border: 1px solid #003d85;
  text-decoration: none !important;
  color: #fff !important;
  background-image: none;
  background-color: #003d85
}

.pure-tabs-hover a,
.pure-tabs-ul a:hover {
  border: 1px solid #003d85;
  border-bottom: 0;
  text-decoration: none !important
}

.pure-tabs-contents div.pure_loading,
div.pure-tabs-result {
  display: none;
  border: 1px solid #DDDDD9;
  margin-top: 0;
  background: #fff;
  padding: 12px
}

.tx-pure-pi {
  display: block
}

.pure-simple-person-single {
  float: left;
  width: 300px;
  min-height: 200px;
  min-width: 20em;
  margin: 0 .8em .8em 0;
  padding: .8em;
  border: 1px solid #DDDDD9;
  background-position: left bottom;
  background-repeat: no-repeat;
  position: relative
}

div#au_content .pure-simple-person-single h3 {
  margin-top: .4em;
  margin-bottom: 0;
  padding-bottom: 0
}

div#au_content .pure-simple-person-single h3 a {
  text-decoration: none
}

div#au_content .pure-simple-person-single h3 a:hover {
  text-decoration: underline
}

.pure-simple-person-single span.title {
  font-variant: small-caps;
  display: block;
  margin-bottom: 0;
  padding-bottom: 0
}

.pure-simple-person-single img {
  position: absolute;
  top: 4.3em;
  max-height: 110px;
  max-width: 90px !important;
  right: 1.4em
}

.pure-simple-person {
  display: table;
  overflow: auto
}

.activities-simple,
.clippings-simple,
.persons-simple,
.projects-simple,
.publication-simple {
  display: table;
  overflow: auto;
  width: 100%
}

.activities-simple .activities,
.clippings-simple .clippings,
.persons-simple .persons,
.projects-simple .projects,
.publication-simple .publications {
  float: left;
  position: relative;
  width: 100%
}

.activities-simple .browse-links,
.clippings-simple .browse-links,
.persons-simple .browse-links,
.projects-simple .browse-links,
.publication-simple .browse-links {
  position: relative;
  float: left
}

.activities-simple a.feedlink,
.clippings-simple a.feedlink,
.persons-simple a.feedlink,
.projects-simple a.feedlink,
.publication-simple a.feedlink {
  background: transparent url('//www.au.dk/typo3conf/ext/pure/res/images/rss.gif') no-repeat scroll top right;
  border: medium none !important;
  color: #777;
  float: right;
  position: relative;
  right: 0;
  height: 14px;
  padding-right: 16px
}

.activities-simple a.feedlink:hover,
.clippings-simple a.feedlink:hover,
.persons-simple a.feedlink:hover,
.projects-simple a.feedlink:hover,
.publication-simple a.feedlink:hover {
  background-position: bottom right
}

.activities-simple .sorting,
.clippings-simple .sorting,
.persons-simple .sorting,
.projects-simple .sorting,
.publication-simple .sorting {
  position: relative;
  float: left
}

.dropdown-sorting {
  float: right;
  padding: 0 0 0 10px
}

.tx-pure-sorting-head {
  background-color: #f2f2f2;
  cursor: pointer;
  padding: 1rem;
  display: block;
  min-width: 180px;
}

.tx-pure-sorting-body {
  display: block;
  background-color: #f2f2f2;
  position: absolute;
  z-index: 20;
  padding: 1rem;
  font-size: 85%;
  min-width: 180px;
}

span.pubtype {
  color: #666;
  font-size: smaller;
  font-variant: small-caps;
  margin-left: .4em;
  margin-right: .4em
}

span.pubstatus {
  background-color: #999;
  color: #FFF;
  font-size: smaller;
  font-variant: small-caps;
  margin-left: .2em;
  margin-right: .2em;
  padding-left: .3em;
  padding-right: .3em
}

table.pure-persons-table {
  margin: 10px 0 15px;
  width: 100%;
  text-align: left
}

table.pure-persons-table tfoot tr th,
table.pure-persons-table thead tr th {
  background-color: #e6EEEE;
  border: 1px solid #FFF;
  padding: 4px
}

table.pure-persons-table thead tr th {
  font-weight: 700;
  text-transform: uppercase
}

table.pure-persons-table thead tr .header {
  background-image: url('//www.au.dk/typo3conf/ext/pure/res/images/bg.gif');
  background-repeat: no-repeat;
  background-position: center right;
  cursor: pointer;
  padding-right: 21px !important
}

table.pure-persons-table tbody td {
  color: #3D3D3D;
  padding: 2px;
  background-color: #FFF;
  vertical-align: top
}

table.pure-persons-table tbody tr.odd td {
  background-color: #F0F0F6
}

table.pure-persons-table thead tr .headerSortUp {
  background-image: url('//www.au.dk/typo3conf/ext/pure/res/images/asc.gif')
}

table.pure-persons-table thead tr .headerSortDown {
  background-image: url('//www.au.dk/typo3conf/ext/pure/res/images/desc.gif')
}

table.pure-persons-table thead tr .headerSortDown,
table.pure-persons-table thead tr .headerSortUp {
  background-color: #8dbdd8
}

.pure_search_form {
  background-color: #EEEEED;
  padding: 2rem;
  white-space: nowrap;
  overflow: auto;

  select {
    width: initial;
    margin: 0;

    &[name="tx_pure_pi[organisationIds]"] {
      width: 100% !important;
    }
  }

  tbody {
    background: none;

    td {
      padding: 1em 0;
    }
  }
}

.pure_search_form select[name="tx_pure_pi[organisationIds]"],
.pure_search_form select[name="tx_pure_pi[publicationCategories]"] {
  width: initial;
}

div.pure-search input[type=text] {}

div.pure-search input[type=submit] {
  @include button();
  font-family: AUPassataRegular;
  min-width: 150px;
}

.person-keyword a {
  text-decoration: none !important;
  color: #000 !important
}

.pure-image-person a {
  border: 1px solid #DDDDD9;
  text-align: center;
  margin: 4px;
  width: 75px;
  display: inline-block;
  height: 100px;
  background-color: #EDEDED;
  vertical-align: middle;
  overflow: hidden
}

.pure-image-person a img {
  max-width: 75px;
  max-height: 100px;
  vertical-align: middle
}

.tx-pure-pi .message-information {
  background-color: #EAF7FF;
  background-image: url(/typo3conf/ext/pure/res/images/information.png);
  color: #4C73A1;
  background-position: 10px 12px;
  background-repeat: no-repeat;
  border: 1px solid;
  margin-bottom: 4px;
  padding: 12px 12px 12px 36px
}

.tx-pure-pi .typo3-message .message-header {
  font-size: 11px;
  font-weight: 700
}

div#au_content div.tx-pure-pi .persons li,
div#au_content div.tx-pure-pi li.portal_list_item {
  list-style-type: none !important
}

div.tx-pure-pi li.portal_list_item a.link {
  border-bottom: none !important
}

div#au_content ol.portal_list {
  margin: 0 !important
}

// Code below this point has been SASS-ified
.pure-simple-person-single {
  background-color: #f0eeef;
  border: 0;
  width: 100%;
  max-width: 305px;

  a {
    border: 0 !important;
  }

  span.title {
    font-variant: normal;
    display: block;
    margin-bottom: 0;
    padding-bottom: 0;
    font-size: 85%;
  }

  img {
    position: absolute;
    bottom: 1em !important;
    max-height: 110px;
    max-width: 90px !important;
    right: 1em;
    top: inherit;
  }

  .organisation {
    display: block;
    margin: .5em 33.33% .8em 0;
    line-height: 1.2em;
    font-size: 85%;
  }

  .icon {
    font-family: "au_icons";
    margin-right: .5em;
    color: #9C9C9C;
    font-size: 115%;
    line-height: 1em;
  }

  .details {
    margin: .8em 30% .8em 0;
    font-size: 85%;
  }

  h3 {
    margin-top: .2em !important;
    margin-bottom: 0 !important;
    ;
  }
}

@media only screen and (max-width: 40em) {
  .pure-simple-person-single {
    max-width: none;
    margin: 1em 0;
  }
}

.pure-simple-person-info {
  width: 180px;
  padding-top: 1em;
}

.pure-simple-person-single .icon {
  font-size: 125%;
  vertical-align: middle;
  color: #666;
  font-weight: normal;
}

div.pure5 a {
  text-decoration-style: dotted;
  text-decoration-color: #888;
}

/*Og reducere størrelsen af sekundære grupperinger <h2> (den primære gruppering har også en <h2>)*/

div.pure5 .groupby-header+.groupby-header h2 {
  font-size: medium;
}

div.pure5 h2.title {
  font-size: medium;
}

div.pure5 div.pure-full-publication td {
  vertical-align: baseline;
  padding: 0 .90909rem;
}

div.pure5 div.pure-full-publication td:first-child {
  width: 100px;
  /* lidt mindre end inline nowrap text */
}

div.pure5 .rendering.rendering_researchoutput {
  display: list-item;
  margin-left: 1.5rem;
  list-style-type: none;
}

div.pure5 .rendering.rendering_researchoutput::before {
  font-family: au_icons;
  content: "≥";
  font-size: .8rem;
  position: absolute;
  margin-left: -1.5rem;
  line-height: 2.5em;
}

div.pure5 div.rendering_clipping p.type,
div.pure5 div.rendering_clipping p.clippingShortRendererReferencesCount {
  display: none;
}

div.pure5 div.rendering_clipping h2.title+p {
  margin: 0;
}

div.pure5 .rss-icon a.feedlink::before {
  content: 'r';
  font-family: "au_icons";
  background-color: #fe8d22;
  color: #fff;
  font-size: 1.4em;
}

div.pure5 .rss-icon a.feedlink {
  text-decoration: none !important;
  background: none;
  padding: 0;
}

div.pure5 .rss-icon {
  float: right;
  position: relative;
  height: 0;
  top: -2.82rem;
  padding: 0.264rem;
}

div.pure5 .tx-pure-sorting-body,
div.pure5 .tx-pure-sorting-head {
  background-color: #f2f2f2;
  padding: 0.2rem 1rem;
  display: block;
  min-width: 180px;
}

div.pure5 .tx-pure-sorting-body li::before {
  line-height: 2.2em;
}

div.pure5 .dropdown-sorting {
  float: none;
  padding: 0;
}

div.pure5 .sorting p {
  margin-bottom: 0;
}

/*styling of possible added onLeave-messages */
.pure5 .vcard .leave {
  background-color: #ece28a;
  padding: 0 8em 0 0.5rem;
  margin: 0 0 0.5em -0.3em;
}

.pure5 .vcard.pure-small-person-single .leave {
  padding: 0 0.5em;
  margin: -0.5em -0.5em 0.5em;
}

.pure5 .vcard.pure-simple-person-single.folded:nth-of-type(2n+1) {
  border-right: 1rem #aaa solid;
  border-bottom: none;
  margin-right: 0;
  clear: left;
}

@media only screen and (max-width: 61em) {
  .pure5 .vcard.pure-simple-person-single.folded:nth-of-type(2n+1) {
    border-right: none;
    border-bottom: 1rem #aaa solid;
    margin-bottom: 0;
  }

  .pure5 .vcard.pure-simple-person-single.folded:nth-of-type(2n) {
    margin-top: 0;
    padding-top: 0.4rem;
    font-size: 85%;
  }
}

/*folded vcards begin*/
.pure5 .vcard.pure-simple-person-single.folded:nth-of-type(2n+1) {
  border-right: 1rem #aaa solid;
  border-bottom: none;
  margin-right: 0;
  clear: left;
  max-width: calc(305px + 1rem);
}

@media only screen and (max-width: 61em) {
  .pure5 .vcard.pure-simple-person-single.folded:nth-of-type(2n+1) {
    border-right: none;
    border-bottom: 1rem #aaa solid;
    margin-bottom: 0;
    max-width: 100%;
    width: 100%;
  }

  .pure5 .vcard.pure-simple-person-single.folded:nth-of-type(2n) {
    margin-right: 0;
    margin-top: 0;
    padding-top: 0.4rem;
    font-size: 85%;
    max-width: 100%;
    width: 100%;
  }
}

.pure5 .pure-simple-person-single {
  min-height: 13.82em;
}

.pure5 .pure-simple-person-single.folded {
  height: 13.82em;
}

.pure5 .pure-simple-person-single.second {
  padding-right: 0;
}

.pure5 .pure-simple-person-single.second .extratext {
  font-size: 1.105rem;
  overflow: auto;
  height: 100%;
}

.pure5 .pure-simple-person-single.second .extratext h1,
.pure5 .pure-simple-person-single.second .extratext h2,
.pure5 .pure-simple-person-single.second .extratext h3 {
  font-size: 1.33636rem;
  margin: 0.6rem 0 0.1rem;
}

.pure5 .pure-simple-person-single.second .extratext ul li {
  margin-bottom: 0;
}

.pure5 .pure-simple-person-single.second .extratext ul li::before {
  line-height: 2.24em;
  font-weight: 400;
  color: #666;
}

/*folded vcards end*/

.pure5 .pure-simple-person-single.nophoto {
  min-height: 12.31em;
  padding-bottom: 0;
}

/*paging begin*/
.tx-pure-pure5-browsebox table {
  width: auto;
}

.tx-pure-pure5-browsebox td {
  border: 1px solid #c8c8c8;
  text-align: center;
  padding: 0;
}

.tx-pure-pure5-browsebox td p {
  padding: 0 0.4em;
  margin: 0;
}

.tx-pure-pure5-browsebox td p a {
  text-decoration: none;
  display: block;
  padding: 0 0.4em;
  margin: 0 -0.4em;
}

.tx-pure-pure5-browsebox-SCell {
  font-weight: bold;
}

/*paging end*/

/* nye */
div.neutral-container div[class*="bg-"] .pure5 .vcard {
  background-color: rgba(0, 37, 70, 0.05);
  border-left: 1px solid #9c9c9c;
}

div.neutral-container div[class*="bg-dark"] .pure5 .vcard {
  background-color: rgba(0, 37, 70, 0.35);
}

div.neutral-container div[class*="bg-"] .pure5 .vcard:only-child {
  border: none;
}

div.neutral-container div[class*="bg-dark"] .pure5 .vcard .icon {
  color: #9c9c9c;
}

/* rettet fra min-width: 20em;  */
.pure5 .vcard {
  min-width: 17em;
}

div.pure .rendering.googlemaps-component {
  display: none;
}

/*visitkort*/
.pure5 .pure-simple-person-single img {
  position: absolute;
  bottom: 1em !important;
  right: 1em;
  top: inherit;
  height: 110px;
  width: 80px;
  object-fit: cover;
  /*vertical-align: middle;  unødvendig slettes*/
}


/*portrætgalleri*/
.pure5 .pure-image-person a img {
  object-fit: cover;
  object-position: center;
  width: 73px;
  min-height: 100px;
  /*vertical-align: middle;  unødvendig slettes*/
  top: 0 !important;
}


/*pub.lister og lign*/
.pure5 .publication-simple .rendering {
  margin-bottom: 1rem;
}


/*ny supplerende rendering af personoplysninger ’minimal information’*/
.pure5 .pure-simple-person-classic h3 {
  margin-top: .2em !important;
}

.pure5 .pure-simple-person-classic .icon {
  font-size: 125%;
  vertical-align: middle;
  color: #666;
  font-weight: 400;
  font-family: au_icons;
  margin-right: .5em;
  line-height: 1em;
}

.pure5 .pure-simple-person-classic .details {
  margin: .0em 30% .8em 0;
  font-size: 85%;
}

div.view_person_photo img {
  width: 150px;
  min-height: 110px;
  /*ny*/
  object-fit: cover;
  /*ny*/
}

.pure5 .pure5-persons-table tr td:first-child {
  vertical-align: top;
}

div.pure div.view_container .portal_graphviewer_button {
  display: none;
}

.pure5 .rendering.rendering_researchoutput.rendering_apa a:last-child span,
.pure5 .rendering.rendering_researchoutput.rendering_harvard a:last-child span {
  word-break: break-word;
}

/* tilføjes for at rette fejl med vores kunstige bullets der sad fast og flød ud over den faste højde (jeg kan demonstrere) */
.pure5 .pure-simple-person-single.second .extratext ul li::before {
  position: inherit;
  margin-right: 0.5em;
}

/* de næste er definitioner for kommende <dl>-strukturer i dobbelte visitkort */
.pure5 .pure-simple-person-single.second .extratext dl dt {
  margin-bottom: 0;
  font-weight: 400;
}

.pure5 .pure-simple-person-single.second .extratext dl dd {
  margin-left: 2.5rem;
}

/* endeligt denne, der er identisk med den der er gældende for .pure5 .pure-simple-person-single.second .extratext ul li::before */
.pure5 .pure-simple-person-single.second .extratext dl dt::before {
  content: "≥";
  font-family: au_icons;
  margin-right: .5rem;
  font-size: 0.8rem;
  line-height: 2.24em;
  color: #666;
}

.pure5 .persons-simple.pure-simple-person .details br+br,
.pure5 .persons-simple.pure-simple-person .extratext br:nth-child(2n+1),
.pure5 .persons-simple.pure-simple-person .extratext h2+br,
.pure5 .persons-simple.pure-simple-person .extratext h3+br {
  display: none;
}

.pure5 .pure-simple-person-single.folded:not(.second) {
  border-right: 6px solid #ccc !important;
  max-width: 311px;
}

.pure5 .pure-simple-person-single.folded.second {
  padding: 0 0 0 0.8rem;
}

.pure5 .pure-simple-person-single.folded.second .extratext {
  scrollbar-width: thin;
  scrollbar-color: #ccc transparent;
  padding: 1.4rem 0;
}

.pure5 .pure-simple-person-single.folded.second .extratext::-webkit-scrollbar {
  width: 6px;
  height: 100%;
}

.pure5 .pure-simple-person-single.folded.second .extratext::-webkit-scrollbar-thumb {
  background-color: #ccc;
}


@media only screen and (max-width: 84em) {
  :not(.related) .pure5 .vcard.pure-simple-person-single.folded:not(.second) {
    border-right: none !important;
    border-bottom: 6px #ccc solid;
    margin-bottom: 0;
    max-width: 100%;
    width: 100%;
  }

  :not(.related) .pure5 .vcard.pure-simple-person-single.folded.second {
    padding-top: 0;
    font-size: 85%;
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 1.8rem;
    max-width: 100%;
    width: 100%;
  }

  :not(.related) .pure5 .pure-simple-person-single.folded.second .extratext {
    height: calc(100% - 0.4rem);
    padding: 0.8rem 0;
    margin-top: 0.4rem;
  }
}


.related .pure5 .vcard.pure-simple-person-single.folded:not(.second) {
  border-right: none !important;
  border-bottom: 6px #ccc solid;
  margin-bottom: 0;
  max-width: 100%;
  width: 100%;
}

.related .pure5 .vcard.pure-simple-person-single.folded.second {
  padding-top: 0;
  font-size: 85%;
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 1.8rem;
  max-width: 100%;
  width: 100%;
}

.related .pure5 .pure-simple-person-single.folded.second .extratext {
  height: calc(100% - 0.4rem);
  padding: 0.8rem 0;
  margin-top: 0.4rem;
}